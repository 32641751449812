import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentWrapper from "../../styles/ContentWrapper"
import { splashScreen } from "../../config"
import { MDXRenderer } from "gatsby-plugin-mdx"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 100px 25px 0 25px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  h2 {
    color: #000000;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 50px;
  margin: 200px 0 30px 0;
  h6,
  strong {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: #000000;
  }
  p {
    margin: 0;
  }
  p:nth-child(2) {
    margin-bottom: 16px;
  }
  ol {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
  li a {
    display: block;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    :hover {
      text-decoration: underline;
    }
  }
  .description {
    grid-column-end: span 2;
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`

const Item = styled.div`
  grid-column-end: span 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  grid-gap: 25px;
  > div,
  a {
    display: block;
    width: calc(50% - 25px);
    position: relative;
    align-self: flex-start;
    :hover span {
      background-color: rgba(0, 0, 255, 0.8);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.2s;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 100%;
    }
  }
  h4 {
    width: 100%;
    padding: 0;
    color: #000;
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
  .gif {
    width: 100%;
  }
`

const SandboxPage = ({ data }) => {
  const { frontmatter, body, exports } = data.sandbox.edges[0].node
  const { sandbox } = exports
  return (
    <Layout splashScreen={splashScreen}>
      <SEO title={frontmatter.title} />
      <StyledSection>
        <StyledContentWrapper>
          <Grid>
            <div>
              <h6>Date:</h6>
              <p>{frontmatter.date}</p>
              <h6>Keywords:</h6>
              <p>{frontmatter.tags.join(", ")}</p>
            </div>
            <p>{frontmatter.description}</p>
            {sandbox.map(
              ({ title, description, tags, date, link, image }, key) => (
                <Item key={key}>
                  <h4>{title}</h4>
                  {title === "Codes&Scripts" ? (
                    <Link to={link} aria-label={title}>
                      {image ? (
                        image.childImageSharp ? (
                          <Img fluid={image.childImageSharp.fluid} />
                        ) : (
                          <img src={image.publicURL} alt="" className="gif" />
                        )
                      ) : null}
                      <span></span>
                    </Link>
                  ) : (
                    <a href={link} aria-label={title}>
                      {image ? (
                        image.childImageSharp ? (
                          <Img fluid={image.childImageSharp.fluid} />
                        ) : (
                          <img src={image.publicURL} alt="" className="gif" />
                        )
                      ) : null}
                      <span></span>
                    </a>
                  )}
                  <div>
                    <h5>{description}</h5>
                    <h6>Type:</h6>
                    <h5>{tags.join(", ")}</h5>
                    <h6>Date: </h6>
                    <h5>{date}</h5>
                  </div>
                </Item>
              )
            )}
            <MDXRenderer>{body}</MDXRenderer>
          </Grid>
        </StyledContentWrapper>
      </StyledSection>
    </Layout>
  )
}

SandboxPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SandboxPage

export const pageQuery = graphql`
  {
    sandbox: allMdx(
      filter: { fileAbsolutePath: { regex: "//sandbox/sandbox.mdx/" } }
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            date
            tags
            description
          }
          exports {
            sandbox {
              title
              description
              tags
              date
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 262, quality: 90) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
